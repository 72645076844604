body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: white;
  background-color: #08aeea;
  background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
}

* {
  box-sizing: border-box;
}
